import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import GAuth from 'vue3-google-oauth2';
import LoadScript from 'vue-plugin-load-script';
const gAuthOptions = {
  clientId: '316381934892-tfnfq246emake2u58e09rff8ahmq9t9r.apps.googleusercontent.com',
};

//
// window.jQuery = require('jquery');
// window.$ = window.jQuery.noConflict();
// window.$ = window.jQuery = require('jquery');
// global.jQuery = require('jquery');
// var $ = global.jQuery;
// window.$ = $;

const app = createApp(App);
app.use(GAuth, gAuthOptions);
app.use(store);
app.use(router);
app.use(LoadScript);
app.mount('#app');
