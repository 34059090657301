// const PROP1_UPDATED = (state, payload) => {
//     state.someObj.prop1 = payload;
// };
//
// const PROP2_UPDATED = (state, payload) => {
//     state.someObj.prop2 = payload;
// };
//
import Cookies from 'js-cookie';

const loginUpdate = (state, payload) => {
  state.name = payload.name;
  state.image = payload.image;
  state.token = payload.token;
  state.role = payload.role;
  state.totalNotifications = payload.totalNotifications;
  state.notifications = JSON.stringify(payload.notifications);
  state.currentRole = 'seeker';

  state.active = payload.active;
  state.email_verify = payload.email_verify;
  state.phone_verify = payload.phone_verify;
  state.unique_id = payload.unique_id;
};

const emailVerifyUpdate = (state, payload) => {
  state.name = payload.name;
  state.image = payload.image;
  state.token = payload.token;
  state.role = payload.role;
  state.totalNotifications = payload.totalNotifications;
  state.notifications = JSON.stringify(payload.notifications);
  state.currentRole = 'seeker';

  state.active = payload.active;
  state.email_verify = payload.email_verify;
  state.phone_verify = payload.phone_verify;
  state.unique_id = payload.unique_id;
};
//eslint-disable-next-line
const logout = (state, payload) => {
  state.name = null;
  state.image = null;
  state.token = null;
  state.role = null;
  state.notifications = null;
  state.currentRole = null;
  state.totalNotifications = null;
  state.active = null;
  state.email_verify = null;
  state.phone_verify = null;
  state.unique_id = null;
  state.status = null;
  // Cookies.remove('')
  Cookies.remove('name');
  Cookies.remove('image');
  Cookies.remove('token');
  Cookies.remove('role');
  Cookies.remove('notifications');
  Cookies.remove('currentRole');
  Cookies.remove('totalNotifications');
  Cookies.remove('currencyCode');
  Cookies.remove('currencySymbol');

  Cookies.remove('active');
  Cookies.remove('email_verify');
  Cookies.remove('phone_verify');
  Cookies.remove('unique_id');
  Cookies.remove('status');
  sessionStorage.clear();
};
const changeCurrentRole = (state, role) => {
  state.currentRole = role;
  Cookies.set('currentRole', role);
};

const changeCurrency = (state, payload) => {
  state.currencyCode = payload.currencyCode;
  state.currencySymbol = payload.currencySymbol;
  Cookies.set('currencyCode', payload.currencyCode);
  Cookies.set('currencySymbol', payload.currencySymbol);
};
const changeTotalNotification = (state, item) => {
  state.totalNotifications = item;
  Cookies.set('totalNotifications', item);
};

const changeActive = (state, item) => {
  state.active = item;
  Cookies.set('active', item);
};
const changePhoneVerify = (state, item) => {
  state.phone_verify = item;
  Cookies.set('phone_verify', item);
};
const changeEmailVerify = (state, item) => {
  state.email_verify = item;
  Cookies.set('email_verify', item);
};
const partnerLoginUpdate = (state, payload) => {
  state.name = payload.name;
  state.token = payload.token;
  state.role = 'partner';
  state.currentRole = 'partner';
  state.status = payload.partner_status;
};

export default {
  loginUpdate,
  emailVerifyUpdate,
  logout,
  changeCurrentRole,
  changeTotalNotification,
  changeCurrency,
  changeActive,
  changePhoneVerify,
  changeEmailVerify,
  partnerLoginUpdate,
};
