import { createRouter, createWebHistory } from 'vue-router';
import { createRouterLayout } from 'vue-router-layout';
// eslint-disable-next-line
import Cookies from 'js-cookie';
// const LayoutLoggedIn = () => import('../components/LayoutLoggedIn');
// const Layout = () => import('../components/Layout.vue');

const Homepage = () => import('../components/homepage/Homepage.vue');
const HWB = () => import('../components/homepage/HWB.vue');
const Faq = () => import('../components/static/faq/Faq2.vue');
const FindCompanion = () => import('../components/static/find-companion/FindCompanion2.vue');
const PackageShipment = () => import('../components/static/package-shipment/PackageShipment.vue');
const ShoppingConcierge = () =>
  import('../components/static/shopping-concierge/ShoppingConcierge.vue');

const BlogList = () => import('../components/blogs/list/BlogList');
const BlogListDynamic = () => import('../components/blogs/list/BlogListDynamic');
const BlogCategory = () => import('../components/blogs/category/BlogCategory.vue');
const Blog1 = () => import('../components/blogs/1/Blog1.vue');
const Blog2 = () => import('../components/blogs/2/Blog2.vue');
const Blog3 = () => import('../components/blogs/3/Blog3.vue');
const Blog4 = () => import('../components/blogs/4/Blog4.vue');
const Blog5 = () => import('../components/blogs/5/Blog5.vue');
const Blog6 = () => import('../components/blogs/6/Blog6.vue');
const Blog7 = () => import('../components/blogs/7/Blog7.vue');
const Blog8 = () => import('../components/blogs/8/Blog8.vue');
const Blog9 = () => import('../components/blogs/9/Blog9.vue');
const Blog10 = () => import('../components/blogs/10/Blog10.vue');
const BlogDetail = () => import('../components/blogs/detail/BlogDetail.vue');
//links footer
const AboutUs = () => import('../components/static/about-us/AboutUs.vue');
const UserTerms = () => import('../components/static/user-terms/UserTerms.vue');
const Privacy = () => import('../components/static/privacy/Privacy.vue');
const Copyright = () => import('../components/static/copyright/Copyright.vue');
const SecurityPolicy = () => import('../components/static/security-policy/SecurityPolicy.vue');
const NewsRoom = () => import('../components/static/news-room/NewsRoom.vue');
const AddFirstService = () => import('@/components/users/add-first-service/AddFirstService.vue');

const Prohibit = () => import('../components/static/prohibit/Prohibit.vue');
const ServiceRateCalculations = () =>
  import('../components/static/service-rate-calculations/ServiceRateCalculations2.vue');
const CustomsExciseDuty = () =>
  import('../components/static/customs-excise-duty/CustomsExciseDuty.vue');
const OverseasOnlineMerchants = () =>
  import('../components/static/overseas-online-merchants/OverseasOnlineMerchants.vue');
const WeightDimensions = () =>
  import('../components/static/weight-dimensions/WeightDimensions.vue');
const ContactUs = () => import('../components/static/contact-us/ContactUs.vue');
const Login = () => import('../components/static/login/Login.vue');
const SignUp = () => import('../components/static/signup/SignUp.vue');
//------------USER----------------------
const ForgotPassword = () => import('../components/users/forgot-password/ForgotPassword');
const ResetPassword = () => import('../components/users/reset-password/ResetPassword');
const ActiveEmailVerify = () => import('../components/users/active/EmailVerify');
const ActivePhoneVerify = () => import('../components/users/active/PhoneVerify.vue');
const ActiveBasicInfo = () => import('../components/users/active/BasicInfo.vue');
const BecomeATraveller = () =>
  import('../components/users/become-a-traveller/BecomeATraveller.vue');
const UserProfile = () => import('@/components/users/user-profile/UserProfile.vue');
const EditProfile = () => import('@/components/users/edit-profile/EditProfile.vue');
const MyAddress = () => import('@/components/users/my-address/MyAddress.vue');
const TrustVerfication = () =>
  import('@/components/users/trust-verification/TrustVerification.vue');
const AccountSettingNotification = () =>
  import('@/components/users/account-setting/Notification.vue');
const AccountSettingPaymentMethod = () =>
  import('@/components/users/account-setting/PaymentMethod.vue');
const AccountSettingPayoutMethod = () =>
  import('@/components/users/account-setting/PayoutMethod.vue');
const AccountSettingTransactionHistory = () =>
  import('@/components/users/account-setting/TransactionHistory.vue');
const AccountSettingPrivacy = () => import('@/components/users/account-setting/Privacy.vue');
const AccountSettingSecurity = () => import('@/components/users/account-setting/Security.vue');
const AccountSettingCancelAccount = () =>
  import('@/components/users/account-setting/CancelAccount.vue');
//------------END USER----------------------
//------------SEEKER----------------------
const DashboardSeekerContent = () => import('../components/dashboard/seeker/SeekerContent.vue');
const SearchSeeker = () => import('../components/search/seeker/SearchSeeker.vue');
const MyTravelNeedSeeker = () =>
  import('../components/my-travel-need/seeker/MyTravelNeedSeeker.vue');
const SeekerTravellerRequest = () => import('../components/request/seeker/TravellerRequest');
//------------END SEEKER----------------------
//------------TRAVELLER----------------------
const DashboardTravellerContent = () =>
  import('../components/dashboard/traveller/TravellerContent.vue');
const SearchTraveller = () => import('../components/search/traveller/SearchTraveller.vue');
const MyTravelNeedTraveller = () =>
  import('../components/my-travel-need/traveller/MyTravelNeedTraveller.vue');
const TravellerSeekerRequest = () => import('../components/request/traveller/SeekerRequest');
//------------END TRAVELLER----------------------
// const PartnerSigunup = () => import('../components/partner/signup/SignUp.vue');
const PartnerSigunupPersonal = () => import('../components/partner/signup/SignUpPersonal.vue');
const PartnerProfileMain = () => import('../components/partner/profile/Main.vue');
const PartnerProfileDetail = () => import('../components/partner/profile/Detail.vue');
const SingupPersonalCheckEmail = () =>
  import('../components/partner/signup/SingupPersonalCheckEmail.vue');
const PartnerSignUpAgency = () => import('../components/partner/signup/SignUpAgency.vue');
const PartnerSignUpAgencyGst = () => import('../components/partner/signup/SignUpAgencyGst.vue');
const PartnerWelcome = () => import('../components/partner/welcome/Welcome.vue');
const PartnerLogin = () => import('../components/partner/login/Login.vue');
const PartnerPassengerAdd = () => import('../components/partner/passenger/add.vue');
const PartnerPassengerList = () => import('../components/partner/passenger/list.vue');
const PartnerPassengerEdit = () => import('../components/partner/passenger/edit.vue');
const PartnerPassengerDelete = () => import('../components/partner/passenger/delete.vue');
const PartnerInitPassword = () => import('../components/partner/signup/InitPassword.vue');
//------------PARTNER----------------------

//------------END PARTNER----------------------
const E404 = () => import('../components/error/404.vue');

// Create <RouterLayout> component.
const RouterLayout = createRouterLayout((layout) => {
  // Resolves a layout component with layout type string.
  return import('@/layouts/' + layout + '.vue');
});

const routes = [
  {
    path: '',
    name: 'Homepage',
    component: Homepage,
  },
  {
    path: '/hwb', //test - homepage with blog
    name: 'HWB',
    component: HWB,
  },
  {
    path: '/news/find-companion',
    name: 'FindCompanion',
    component: FindCompanion,
  },
  {
    path: '/news/package-shipment',
    name: 'PackageShipment',
    component: PackageShipment,
  },
  {
    path: '/news/shopping-concierge',
    name: 'ShoppingConcierge',
    component: ShoppingConcierge,
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
  },
  // {
  //   path: '/service-rate-calculations/people',
  //   name: 'ServiceRateCalculationsPeople',
  //   component: Layout,
  //   props: { page: 'ServiceRateCalculations', type: 'people' },
  // },
  // {
  //   path: '/service-rate-calculations/package',
  //   name: 'ServiceRateCalculationsPackage',
  //   component: Layout,
  //   props: { page: 'ServiceRateCalculations', type: 'package' },
  // },
  // {
  //   path: '/service-rate-calculations/product',
  //   name: 'ServiceRateCalculationsProduct',
  //   component: Layout,
  //   props: { page: 'ServiceRateCalculations', type: 'product' },
  // },
  {
    path: '/service-rate-calculations/:serviceType(people|package|product)',
    name: 'ServiceRateCalculations',
    component: ServiceRateCalculations,
  },
  //footer linlks
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/user-terms',
    name: 'UserTerms',
    component: UserTerms,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/copyright',
    name: 'Copyright',
    component: Copyright,
  },
  {
    path: '/security-policy',
    name: 'SecurityPolicy',
    component: SecurityPolicy,
  },
  {
    path: '/news-room',
    name: 'NewsRoom',
    component: NewsRoom,
  },
  {
    path: '/prohibited-or-restricted-items',
    name: 'Prohibit',
    component: Prohibit,
  },
  {
    path: '/customs-excise-duty',
    name: 'CustomsExciseDuty',
    component: CustomsExciseDuty,
  },
  {
    path: '/customs-excise-duty',
    name: 'CustomsExciseDuty',
    component: CustomsExciseDuty,
  },
  {
    path: '/overseas-online-merchants',
    name: 'OverseasOnlineMerchants',
    component: OverseasOnlineMerchants,
  },
  {
    path: '/weight-and-dimensions',
    name: 'WeightDimensions',
    component: WeightDimensions,
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  // {
  //   path: '/email-verify',
  //   name: 'ActiveEmailVerify',
  //   component: ActiveEmailVerify,
  // },
  {
    path: '/email-verify/:token?',
    name: 'ActiveEmailVerify',
    component: ActiveEmailVerify,
  },
  {
    path: '/phone-verify',
    name: 'ActivePhoneVerify',
    component: ActivePhoneVerify,
  },
  {
    path: '/update-basic-info',
    name: 'ActiveBasicInfo',
    component: ActiveBasicInfo,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/become-a-traveller',
    name: 'BecomeATraveller',
    component: BecomeATraveller,
  },
  {
    path: '/become-a-traveller/first-service',
    name: 'AddFirstService',
    component: AddFirstService,
  },
  {
    path: '/profile/:unique_id',
    name: 'UserProfile',
    component: UserProfile,
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: EditProfile,
  },
  {
    path: '/user/my-address',
    name: 'MyAddress',
    component: MyAddress,
  },
  {
    path: '/user/trust-verification',
    name: 'TrustVerfication',
    component: TrustVerfication,
  },
  {
    path: '/account-setting/notification',
    name: 'AccountSettingNotification',
    component: AccountSettingNotification,
  },
  {
    path: '/account-setting/payment-method',
    name: 'AccountSettingPaymentMethod',
    component: AccountSettingPaymentMethod,
  },
  {
    path: '/account-setting/payout-method',
    name: 'AccountSettingPayoutMethod',
    component: AccountSettingPayoutMethod,
  },
  {
    path: '/account-setting/transaction-history',
    name: 'AccountSettingTransactionHistory',
    component: AccountSettingTransactionHistory,
  },
  {
    path: '/account-setting/privacy',
    name: 'AccountSettingPrivacy',
    component: AccountSettingPrivacy,
  },
  {
    path: '/account-setting/security',
    name: 'AccountSettingSecurity',
    component: AccountSettingSecurity,
  },
  {
    path: '/account-setting/cancel-account',
    name: 'AccountSettingCancelAccount',
    component: AccountSettingCancelAccount,
  },
  //
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  // {
  //   path: '/blogs',
  //   name: 'BlogList',
  //   component: BlogList,
  //   children: [
  //     { path: '/the-future-of-travel', component: Blog1 },
  //     { path: '/having-a-flight-companion-means-more-than-just-company', component: Blog2 },
  //     { path: '/india-announces-air-bubbles-with-us-france', component: Blog3 },
  //   ],
  // },
  {
    path: '/blogs',
    name: 'BlogList',
    component: BlogList,
  },
  {
    path: '/blogs-dynamic',
    name: 'BlogListDynamic',
    component: BlogListDynamic,
  },
  {
    path: '/blog-category/:slug',
    name: 'BlogCategory',
    component: BlogCategory,
  },
  {
    path: '/blogs/the-future-of-travel',
    name: 'Blog1',
    component: Blog1,
  },
  {
    path: '/blogs/having-a-flight-companion-means-more-than-just-company',
    component: Blog2,
    name: 'Blog2',
  },
  {
    path: '/blogs/india-announces-air-bubbles-with-us-france',
    component: Blog3,
    name: 'Blog3',
  },
  {
    path: '/blogs/travel-anxiety-how-a-travel-companion-can-help-ease-your-travel-anxiety',
    component: Blog4,
    name: 'Blog4',
  },
  {
    path: '/blogs/travel-anxiety-how-a-travel-companion-can-help-ease-your-travel-anxiety',
    component: Blog4,
    name: 'Blog4',
  },
  {
    path: '/blogs/international-parcel-shipping-tips-for-overseas-shipments',
    component: Blog5,
    name: 'Blog5',
  },
  {
    path: '/blogs/can-you-use-westjet-companion-flight-for-vacation-packages',
    component: Blog6,
    name: 'Blog6',
  },
  {
    path: '/blogs/how-to-be-a-good-traveller-your-best-travel-apps-for-the-trip',
    component: Blog7,
    name: 'Blog7',
  },
  {
    path: '/blogs/an-air-and-land-travel-boom-is-on-the-way',
    component: Blog8,
    name: 'Blog8',
  },
  {
    path: '/blogs/flight-companion-for-elderly',
    component: Blog9,
    name: 'Blog9',
  },
  {
    path: '/blogs/how-you-can-celebrate-festive-seasons-like-never-before',
    component: Blog10,
    name: 'Blog10',
  },
  {
    path: '/blog-detail/:slug',
    component: BlogDetail,
    props: { page: 'BlogDetail' },
    name: 'BlogDetail',
  },

  {
    path: '/seeker/dashboard',
    component: DashboardSeekerContent,
    name: 'DashboardSeekerContent',
  },
  {
    path: '/seeker/my-travel-need',
    component: MyTravelNeedSeeker,
    name: 'MyTravelNeedSeeker',
  },
  {
    path: '/seeker/search',
    component: SearchSeeker,
    name: 'SearchSeeker',
  },
  {
    path: '/seeker/traveller-request',
    component: SeekerTravellerRequest,
    name: 'SeekerTravellerRequest',
  },

  {
    path: '/traveller/dashboard',
    component: DashboardTravellerContent,
    name: 'DashboardTravellerContent',
  },
  {
    path: '/traveller/my-travel-need',
    component: MyTravelNeedTraveller,
    name: 'MyTravelNeedTraveller',
  },
  {
    path: '/traveller/search',
    component: SearchTraveller,
    name: 'SearchTraveller',
  },
  {
    path: '/traveller/seeker-request',
    component: TravellerSeekerRequest,
    name: 'TravellerSeekerRequest',
  },
  {
    path: '/partner/signup-personal',
    component: PartnerSigunupPersonal,
    name: 'PartnerSigunupPersonal',
  },
  {
    path: '/partner/signup-personal-check-email',
    component: SingupPersonalCheckEmail,
    name: 'SingupPersonalCheckEmail',
  },
  {
    path: '/partner/signup-agency',
    component: PartnerSignUpAgency,
    name: 'PartnerSignUpAgency',
  },
  {
    path: '/partner/signup-agency-gst',
    component: PartnerSignUpAgencyGst,
    name: 'PartnerSignUpAgencyGst',
  },
  {
    path: '/partner/welcome',
    component: PartnerWelcome,
    name: 'PartnerWelcome',
  },
  {
    path: '/partner/update/:slug',
    component: PartnerProfileMain,
    name: 'PartnerProfileMain',
  },
  {
    path: '/partner/detail',
    component: PartnerProfileDetail,
    name: 'PartnerProfileDetail',
  },
  {
    path: '/partner/login',
    component: PartnerLogin,
    name: 'PartnerLogin',
  },
  {
    path: '/partner/passenger/add',
    component: PartnerPassengerAdd,
    name: 'PartnerPassengerAdd',
  },
  {
    path: '/partner/passenger/list',
    component: PartnerPassengerList,
    name: 'PartnerPassengerList',
  },
  {
    path: '/partner/passenger/edit/:id',
    component: PartnerPassengerEdit,
    name: 'PartnerPassengerEdit',
  },
  {
    path: '/partner/passenger/delete/:id',
    component: PartnerPassengerDelete,
    name: 'PartnerPassengerDelete',
  },
  {
    path: '/partner/init-password',
    component: PartnerInitPassword,
    name: 'PartnerInitPassword',
  },

  //--------------END TRAVELLER------------
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: E404, props: { page: 'E404' } },
];
// history: createWebHistory(process.env.BASE_URL),
// const router = createRouter({
//   history: createWebHistory(process.env.VUE_APP_BASE_URL),
//   routes,
//   //eslint-disable-next-line
//   scrollBehavior(to, from, savedPosition) {
//     return { x: 0, y: 0 };
//   },
// });
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes: [
    {
      path: '/',

      // Pass <RouterLayout> as the route component
      component: RouterLayout,

      // All child components will be applied with corresponding layout component
      children: routes,
    },
  ],
  // //eslint-disable-next-line
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 };
  // },
});
// eslint-disable-next-line
// router.beforeEach((to, from) => {
//   return { name: 'PartnerLogin' };
// next({ name: 'PartnerLogin' });
// return;
// next();
//--------test page
// if (to.path.startsWith('/blogs')) next();
//--------end test page
// if (to.name !== 'Homepage' && sessionStorage.getItem('token') === null)
//   next({ name: 'Homepage' });
// else next();
// let isAuthenticated = 0; //0 unlogin, 1: seeker, 2 seeker and traveller
// if (sessionStorage.getItem('token') === null) {
// }
// eslint-disable-next-line
  // let role = Cookies.get('role');
// switch (role) {
//   case 'partner':
//     // eslint-disable-next-line no-case-declarations
//     const partnerRouter = [
//       'PartnerPassengerAdd',
//       'PartnerPassengerList',
//       'PartnerPassengerEdit',
//       'PartnerPassengerDelete',
//       'PartnerProfileDetail',
//       'PartnerProfileMain',
//       'PartnerWelcomer',
//     ];
//     if (!partnerRouter.includes(to.name)) {
//       next({ name: 'Homepage' });
//     }
//     break;
//   case 's':
//     break;
//   case 't':
//     break;
//   case 'st':
//     break;
// }
// });
//eslint-disable-next-line
// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0);
//   // ...
// });
// eslint-disable-next-line
router.beforeEach(async (to, from) => { //working
  let role = Cookies.get('role');
  const partnerRouter = [
    'PartnerPassengerAdd',
    'PartnerPassengerList',
    'PartnerPassengerEdit',
    'PartnerPassengerDelete',
    'PartnerProfileDetail',
    'PartnerProfileMain',
    'PartnerWelcomer',
  ];
  if (partnerRouter.includes(to.name)) {
    if (role != 'partner' && to.name != 'PartnerLogin') {
      return { name: 'PartnerLogin' };
    }
  }
});
export default router;
