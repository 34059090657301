import { createStore } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import Cookies from 'js-cookie';
// import getters from './getters'
// import actions from './actions'
// import mutations from './mutations'

export default createStore({
  state: {
    name: Cookies.get('name'),
    image: Cookies.get('image'),
    token: Cookies.get('token'),
    role: Cookies.get('role'),
    currentRole: Cookies.get('currentRole'),
    notifications: sessionStorage.getItem('notifications'),
    totalNotifications: Cookies.get('totalNotifications'),
    currencyCode: Cookies.get('currencyCode'),
    currencySymbol: Cookies.get('currencySymbol'),
    active: Cookies.get('active'),
    email_verify: Cookies.get('email_verify'),
    phone_verify: Cookies.get('phone_verify'),
    unique_id: Cookies.get('unique_id'),
    status: Cookies.get('status'),
  },
  mutations,
  actions,
  getters,
  modules: {},
});
